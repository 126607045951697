import { useCallback, useState } from 'react';

import { AppEvents, SelectableValue } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import appEvents from 'app/core/app_events';

import { PRODUCTION_PLUGIN_BASE_URL, SELECTED_STATETIME_RANGE } from './constants';

export const useIncidentViewModel = () => {
  const [incident, setIncident] = useState<string>('');
  const [incidentGroup, setIncidentGroup] = useState<string>('');
  const [isIncidentOpen, setIsIncidentOpen] = useState<boolean>(false);
  const [isIncidentSelected, setIsIncidentSelected] = useState<boolean>(false);
  const [incidentOptions, setIncidentOptions] = useState<Array<SelectableValue<string>>>([]);
  const [openTime, setOpenTime] = useState<string>('8');
  const [teamNumber, setTeamNumber] = useState<string>('3');
  const [pauseTime, setPauseTime] = useState<string>('1');
  const [partsByHour, setPartsByHour] = useState<string>('1');

  const fetchIncidents = async () => {
    try {
      const prodConfigJsonData = await getBackendSrv().get(PRODUCTION_PLUGIN_BASE_URL);
      if (prodConfigJsonData.jsonData) {
        const incidentList = JSON.parse(prodConfigJsonData.jsonData?.incidentList);
        setIncidentOptions([...incidentList]);
        setOpenTime(prodConfigJsonData.jsonData?.openTime);
        setTeamNumber(prodConfigJsonData.jsonData?.teamNumber);
        setPauseTime(prodConfigJsonData.jsonData?.pauseTime);
        setPartsByHour(prodConfigJsonData.jsonData?.partsByHour);
      }
    } catch (error) {
      console.error('Error on read production parameters:', error);
    }
  };

  const onSelectIncident = useCallback((selectable: SelectableValue<string>) => {
    if (selectable.value !== undefined && selectable.description !== undefined) {
      setIncident(selectable.value);
      setIncidentGroup(selectable.description);
      setIsIncidentSelected(true);
    }
  }, []);

  const onOpenIncident = () => {
    setIsIncidentOpen(true);
    setIsIncidentSelected(false);
    fetchIncidents();
  };

  const onCloseIncident = () => {
    setIsIncidentOpen(false);
  };

  const onSaveIncident = useCallback(async () => {
    let host = window.location.host;
    let incidentApiUrl: string;

    const selectedTSRange = localStorage.getItem(SELECTED_STATETIME_RANGE);
    // const appFrontSettings = await getBackendSrv().get('/api/frontend/settings');

    if (host.includes(':')) {
      host = host.split(':')[0];
    }
    incidentApiUrl = `http://${host}:9997/api/v1/incidents`;
    if (host.includes('lesly')) {
      incidentApiUrl = `https://${host}/api/v1/incidents`;
    }

    if (selectedTSRange === null) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: ['Cannot add new incident for this intervall with no end date'],
      });
    }

    if (selectedTSRange) {
      const selectedTSRangeJson = JSON.parse(selectedTSRange);
      const incidentBody = {
        incident: incident,
        incidentDates: { ...selectedTSRangeJson },
        openTime: openTime,
        teamNumber: teamNumber,
        uiSource: 'timeline-panel',
        dateType: 'START',
        incidentGroup: incidentGroup,
        pauseTime: pauseTime,
        partsByHour: partsByHour,
      };

      let reqOptions = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(incidentBody),
      };
      try {
        const response = await fetch(incidentApiUrl, reqOptions);
        if (response.ok) {
          appEvents.publish({
            type: AppEvents.alertSuccess.name,
            payload: ['New incident added' + ': ' + response.status + ' (' + response.statusText + ')'],
          });
        }
      } catch (error) {
        console.error('Error on added incident:', error);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: ['Cannot add new incident'],
        });
      }
    }

    onCloseIncident();
  }, [incident, incidentGroup, openTime, partsByHour, pauseTime, teamNumber]);

  return {
    incident,
    isIncidentOpen,
    incidentOptions,
    isIncidentSelected,
    onSelectIncident,
    onOpenIncident,
    onSaveIncident,
    onCloseIncident,
    fetchIncidents,
  };
};
