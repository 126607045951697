import { SelectableValue } from '@grafana/data';

export const SELECTED_STATETIME_RANGE = 'grafana.panel.statetimeline.range';
export const PRODUCTION_PLUGIN_BASE_URL = '/api/plugins/lesly-production-app/settings';

export const INCIDENT_LIST: Array<SelectableValue<string>> = [
  { label: 'Absence Moyen de contrôle', value: 'ABSENCE_MOYEN_DE_CONTROLE' },
  { label: 'Absence opérateur: congé', value: 'ABSENCE_OPERATEUR_CONGE' },
  { label: 'Absence Opérateur: maladie', value: 'ABSENCE_OPERATEUR_MALADIE' },
  { label: 'Absence Opérateur: retard', value: 'ABSENCE_OPERATEUR_RETARD' },
  { label: 'Absence opérateur: réunion', value: 'ABSENCE_OPERATEUR_REUNION' },
  { label: 'Casse outil', value: 'CASSE_OUTIL' },
  { label: 'Contrôle 1er article en Zeiss', value: 'CONTROLE_1ER_ARTICLE_EN_ZEISS' },
  { label: 'Cycle de chauffe', value: 'CYCLE_CHAUFFE' },
  { label: 'Défaillance inconnue', value: 'DEFAILLANCE_INCONNUE' },
  { label: 'Défaillance groupe hydrolique', value: 'DEFAILLANCE_GROUPE_HYDROLIQUE' },
  { label: 'Défaillance lubrification outil', value: 'DEFAILLANCE_LUBRIFICATION_OUTIL' },
  { label: 'Défaillance lubrification pièce', value: 'DEFAILLANCE_LUBRIFICATION_PIECE' },
  { label: 'Défaillance lubrification', value: 'DEFAILLANCE_LUBRIFICATION' },
  { label: 'Défaut pièce', value: 'DEFAUT_PIECE' },
  { label: 'Défaut tolérance état de surface', value: 'DEFAUT_TOLERANCE_ETAT_SURFACE' },
  { label: 'Défaut variateur', value: 'DEFAUT_VARIATEUR' },
  { label: 'Encombrement', value: 'ENCOMBREMENT' },
  { label: 'Encrassement', value: 'ENCRASSEMENT' },
  { label: 'Maintenance annuelle', value: 'MAINTENANCE_ANNUELLE' },
  { label: 'Mise au Point Industrialisation', value: 'MISE_AU_POINT_INDUSTRIALISATION' },
  { label: 'Perturbation électromagnétique', value: 'PERTURBATION_ELECTROMAGNETIQUE' },
  { label: 'Préparation changement de série', value: 'PREPARATION_CHANGEMENT_SERIE' },
  { label: 'Problème pièce', value: 'PROBLEME_PIECE' },
  { label: 'Problème qualité', value: 'PROBLEME_QUALITE' },
  { label: 'Rupture approvionnment matière', value: 'RUPTURE_APPROVISIONNEMENT_MATIERE' },
  { label: 'Rupture approvionnment outil', value: 'RUPTURE_APPROVISIONNEMENT_OUTIL' },
  { label: 'Rupture outil', value: 'RUPTURE_OUTIL' },
  { label: 'Sous charge', value: 'SOUS_CHARGE' },
  { label: 'Usure', value: 'USURE' },
];
